var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import AppConfig from "~/config/app.config";
import { Dependencies } from "~/core/decorator";
import { State, Mutation, Action, namespace } from "vuex-class";
import { StorageService } from "~/utils/storage.service";
import { UserService } from "~/services/manage-service/user.service";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
var workspaceModule = namespace("workspace");
var Login = /** @class */ (function (_super) {
    __extends(Login, _super);
    function Login() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loginRule = {
            username: {
                required: true,
                message: "员工工号不能为空",
                trigger: "blur",
            },
            password: {
                required: true,
                message: "密码不能为空",
                trigger: "blur",
            },
        };
        _this.loginModel = {
            username: "",
            password: "",
        };
        _this.appConfig = AppConfig;
        _this.remember = false;
        _this.dialog = {
            workPassword: false,
        };
        return _this;
    }
    Login.prototype.mounted = function () {
        var rember = StorageService.getItem("remember");
        this.remember = !!rember;
        if (rember) {
            this.loginModel = rember;
        }
    };
    /**
     * 提交登录表单
     */
    Login.prototype.submitForm = function () {
        var _this = this;
        var loginForm = this.$refs["login-form"];
        loginForm.validate(function (success) {
            if (!success)
                return;
            if (_this.remember) {
                StorageService.setItem("remember", _this.loginModel);
            }
            else {
                StorageService.removeItem("remember");
            }
            _this.userService
                .login({
                username: _this.loginModel.username,
                password: _this.loginModel.password,
            })
                .subscribe(function (_a) {
                var token = _a.token, userModel = _a.userModel;
                _this.updateUserLoginData({
                    token: token,
                    userModel: userModel,
                });
                var admin = _this.menuResource.find(function (v) { return v.name === "首页"; });
                _this.updateCurrentTopMenu(admin);
                StorageService.setItem("userInfoStage", _this.loginModel);
                // 跳转工作区
                if (userModel) {
                    _this.$router.push("/dashboard/dashboard-bulletin");
                }
                else {
                    _this.$router.push("/");
                }
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    __decorate([
        Dependencies(CollectionCaseService)
    ], Login.prototype, "collectionCaseService", void 0);
    __decorate([
        Dependencies(UserService)
    ], Login.prototype, "userService", void 0);
    __decorate([
        State
    ], Login.prototype, "menuResource", void 0);
    __decorate([
        Mutation
    ], Login.prototype, "updateCurrentTopMenu", void 0);
    __decorate([
        Action("updateUserLoginData")
    ], Login.prototype, "updateUserLoginData", void 0);
    __decorate([
        workspaceModule.State
    ], Login.prototype, "currentTabs", void 0);
    __decorate([
        workspaceModule.Mutation("updateCurrentTabs")
    ], Login.prototype, "updateCurrentTabs", void 0);
    Login = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon,
            },
        })
    ], Login);
    return Login;
}(Vue));
export default Login;
